'use strict';

(function () {
  var self;
  class AddEditTurbineModalCtrl {
    constructor($scope, $state, $uibModalInstance, Site, Turbine, options, usSpinnerService, Util, ActivityLog, Auth) {
      this.Util = Util;
      this.Site = Site;
      this.Turbine = Turbine;
      this.$scope = $scope;
      this.$state = $state;
      this.usSpinnerService = usSpinnerService;
      this.$uibModalInstance = $uibModalInstance;
      this.isDisabled = false;
      this.errors = {};
      this.popup1 = {
        opened: false
      };
      this.popup2 = {
        opened: false
      };
      this.options = options;
      this.submitted = false;
      this.ActivityLog = ActivityLog;
      self = this;
      Auth.isLoggedInAsync((loggedIn) => {
        if (loggedIn) {
          this.currentUser = Auth.getCurrentUser();
        }
      });

      this.turbine = {
        site: this.options.site._id,
        // make: this.options.turbine ? this.options.turbine.make : this.options.site.turbines[this.options.site.turbines.length-1].make,
        make: this.options.turbine ? this.options.turbine.make : this.options.site.turbines[this.options.site.turbines.length-1] ? this.options.site.turbines[this.options.site.turbines.length-1].make :  null,
        model: this.options.turbine ? this.options.turbine.model : this.options.site.turbines[this.options.site.turbines.length-1] ? this.options.site.turbines[this.options.site.turbines.length-1].model : null,
        serial: this.options.turbine ? this.options.turbine.serial : null,
        capacity: this.options.turbine ? this.options.turbine.capacity : this.options.site.turbines[this.options.site.turbines.length-1] ? this.options.site.turbines[this.options.site.turbines.length-1].capacity : null,
        exportedEnergy: this.options.turbine ? this.options.turbine.exportedEnergy : null,
        towerHeight: this.options.turbine ? this.options.turbine.towerHeight : this.options.site.turbines[this.options.site.turbines.length-1] ? this.options.site.turbines[this.options.site.turbines.length-1].towerHeight : null,
        hubHeight: this.options.turbine ? this.options.turbine.hubHeight : this.options.site.turbines[this.options.site.turbines.length-1] ? this.options.site.turbines[this.options.site.turbines.length-1].hubHeight : null,
        sitePosition: this.options.turbine ? this.options.turbine.sitePosition : null,
        type: this.options.turbine ? this.options.turbine.type : null,
        manufactured: this.options.turbine ? this.options.turbine.manufactured : null,
        commissioning: this.options.turbine && this.options.turbine.commissioning ? new Date(this.options.turbine.commissioning) : null
      };

      if (this.options.turbine && this.options.turbine._id) {
        this.turbine._id = this.options.turbine._id;
      }

      $scope.$on('$stateChangeStart', function () {
        $uibModalInstance.dismiss();
      });
    }

    saveTurbine(form) {
      this.submitted = true;
      this.serverError = null;
      if (form.$valid) {
        this.isDisabled = true;
        this.startSpin('spinner-1');
        if (this.turbine._id) {
          this.Turbine.update(this.turbine).$promise
            .then(turbine => {
              this.stopSpin('spinner-1');
              console.log(turbine);
              let turbineStr = '';
              if (turbine.data.make) {
                turbineStr += turbine.data.make
              }
              if (turbine.data.model) {
                turbineStr += turbine.data.model
              }
              if (turbine.data.name) {
                turbineStr += turbine.data.name
              }
              const activityLogData = {
                site: this.options.site._id,
                turbine: turbine.data._id,
                user: this.currentUser._id,
                source: 'web',
                action: 'edit',
                type: 'turbine',
                sourceScreen: 'turbine',
                eventString: `Turbine <a href="/turbine/${turbine.data._id}">${turbineStr}</a> was edited in wind farm <a href="/site/${this.options.site._id}">${this.options.site.name}</a> by ${this.currentUser.name}`
              }

              this.ActivityLog.save(activityLogData).$promise
                .then(activityLog => {
                  console.log(activityLog);
                })
                .catch(err => {
                  console.log('Error inserting activityLog', err);
                });
                this.$uibModalInstance.close();
                this.$state.reload();
            })
            .catch(err => {
              this.isDisabled = false;
              this.errors = {};
              this.stopSpin('spinner-1');
              this.handleError(err, form);
            });
        } else {
          // debugger;
          let number = this.turbine.sitePosition;
          let position = number.split("-");
          let result = number.includes("-");
          let count = parseInt(position[1]);
          let firstcount = parseInt(position[0]);
          if(!result) {
            this.Turbine.save(this.turbine).$promise
            .then(turbine => {
              this.stopSpin('spinner-1');
              console.log(turbine);
              let turbineStr = '';
              if(turbine.data.make) {
                turbineStr+=turbine.data.make
              }
              if(turbine.data.model) {
                turbineStr+=turbine.data.model
              }
              if(turbine.data.name) {
                turbineStr+=turbine.data.name
              }
              const activityLogData = {
                site: this.options.site,
                turbine: turbine.data._id,
                user: this.currentUser._id,
                source: 'web',
                action: 'create',
                type: 'turbine',
                sourceScreen: 'site',
                eventString: `Turbine <a href="/turbine/${turbine.data._id}">${turbineStr}</a> was created in wind farm <a href="/site/${this.options.site._id}">${this.options.site.name}</a> by ${this.currentUser.name}`
              }

              this.ActivityLog.save(activityLogData).$promise
                .then(activityLog => {
                  console.log(activityLog);
                })
                .catch(err => {
                  console.log('Error inserting activityLog', err);
              });
              this.$uibModalInstance.close();
              this.$state.reload();
            })
            .catch(err => {
              this.isDisabled = false;
              this.errors = {};
              this.stopSpin('spinner-1');
              this.handleError(err, form);
            });
        } else {
          if(count > firstcount) {
          var array = [];
          for (let i = firstcount; i <= count; i++) {
            array.push(i.toString());
          }
          let progressCount = 1;
          async.mapSeries(array, (id, callback) => {
            this.turbine.sitePosition = id;
            this.displayProgress = `Processing ${progressCount} of ${array.length}`;
            progressCount = progressCount + 1;
            this.Turbine.save(this.turbine).$promise
              .then(turbine => {
                console.log(turbine);
                let turbineStr = '';
                if (turbine.data.make) {
                  turbineStr += turbine.data.make
                }
                if (turbine.data.model) {
                  turbineStr += turbine.data.model
                }
                if (turbine.data.name) {
                  turbineStr += turbine.data.name
                }
                const activityLogData = {
                  site: this.options.site,
                  turbine: turbine.data._id,
                  user: this.currentUser._id,
                  source: 'web',
                  action: 'create',
                  type: 'turbine',
                  sourceScreen: 'site',
                  eventString: `Turbine <a href="/turbine/${turbine.data._id}">${turbineStr}</a> was created in wind farm <a href="/site/${this.options.site._id}">${this.options.site.name}</a> by ${this.currentUser.name}`
                }

                this.ActivityLog.save(activityLogData).$promise
                  .then(activityLog => {
                    console.log(activityLog);
                    callback(null, id);
                  })
                  .catch(err => {
                    console.log('Error inserting activityLog', err);
                  });
              })
              .catch(err => {
                this.isDisabled = false;
                this.errors = {};
                this.stopSpin('spinner-1');
                this.handleError(err, form);
              });
          }, function(err, results) {
            if(err) {
              console.log(err);
            }
            self.$uibModalInstance.close();
            self.stopSpin('spinner-1');
            self.$state.reload();
            console.log('results', results);
          });
          } else {
            this.isDisabled = false;
            this.stopSpin('spinner-1');
            this.serverError = 'please enter valid pad/site position.';
            // console.log(err);
            // this.stopSpin('spinner-1');
          }
        }
        }
      }
    }

    handleError(err, form) {
      if (err && err.data && err.data.meta && err.data.meta.error_message) {
        if (err.data.meta.code && err.data.meta.code != 422 && !this.Util.isArray(err.data.meta.error_message)) {
          this.serverError = err.data.meta.error_message || 'Internal Server Error';
        } else {
          err.data.meta.error_message.forEach(errorMessage => {
            for (let fieldName in errorMessage) {
              if (form[fieldName]) {
                form[fieldName].$setValidity('mongoose', false);
                this.errors[fieldName] = errorMessage[fieldName];
              }
            }
          })
        }
      } else {
        this.serverError = 'Internal Server Error';
        console.log(err);
      }
    }

    openManufacturedDate() {
      this.popup1.opened = true;
    }

    openCommisioningDate() {
      this.popup2.opened = true;
    }

    cancelSave() {
      this.$uibModalInstance.dismiss('cancel');
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }
  angular.module('windmanagerApp')
    .controller('AddEditTurbineModalCtrl', AddEditTurbineModalCtrl);
})();                                        